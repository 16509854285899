import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import AuthLayout from '@/views/Pages/AuthLayout.vue';
// GeneralViews
import NotFound from '@/views/GeneralViews/NotFoundPage.vue';

// Calendar
const Calendar = () => import(/* webpackChunkName: "extra" */ '@/views/Calendar/Calendar.vue');
// Charts
const Charts = () => import(/* webpackChunkName: "dashboard" */ '@/views/Charts.vue');

// Components pages
const Buttons = () => import(/* webpackChunkName: "components" */ '@/views/Components/Buttons.vue');
const Cards = () => import(/* webpackChunkName: "components" */ '@/views/Components/Cards.vue');
const GridSystem = () => import(/* webpackChunkName: "components" */ '@/views/Components/GridSystem.vue');
const Notifications = () => import(/* webpackChunkName: "components" */ '@/views/Components/Notifications.vue');
const Icons = () => import(/* webpackChunkName: "components" */ '@/views/Components/Icons.vue');
const Typography = () => import(/* webpackChunkName: "components" */ '@/views/Components/Typography.vue');

// Dashboard pages
const Dashboard = () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/Dashboard.vue');
const AlternativeDashboard = () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/AlternativeDashboard.vue');
const Widgets = () => import(/* webpackChunkName: "dashboard" */ '@/views/Widgets.vue');

// Forms pages
const FormElements = () => import(/* webpackChunkName: "forms" */ '@/views/Forms/FormElements.vue');
const FormComponents = () => import(/* webpackChunkName: "forms" */ '@/views/Forms/FormComponents.vue');
const FormValidation = () => import(/* webpackChunkName: "forms" */ '@/views/Forms/FormValidation.vue');

// Maps pages
const GoogleMaps = () => import(/* webpackChunkName: "extra" */ '@/views/Maps/GoogleMaps.vue');
const VectorMaps = () => import(/* webpackChunkName: "extra" */ '@/views/Maps/VectorMaps.vue');

// Pages
const User = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/UserProfile.vue');
const Pricing = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Pricing.vue');
const TimeLine = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/TimeLinePage.vue');
const Login = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Login.vue');
const Home = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Home.vue');
const Register = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Register.vue');
const Lock = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Lock.vue');

// TableList pages
const RegularTables = () => import(/* webpackChunkName: "tables" */ '@/views/Tables/RegularTables.vue');
const SortableTables = () => import(/* webpackChunkName: "tables" */ '@/views/Tables/SortableTables.vue');
const PaginatedTables = () => import(/* webpackChunkName: "tables" */ '@/views/Tables/PaginatedTables.vue');

// Setup pages
const Users = () => import(/* webpackChunkName: "tables" */ '@/views/Setup/UsersFolder/Users.vue');
const AddUsers = () => import(/* webpackChunkName: "tables" */ '@/views/Setup/UsersFolder/AddUsers.vue');
const EditUsers = () => import(/* webpackChunkName: "tables" */ '@/views/Setup/UsersFolder/EditUsers.vue');
const MyUserProfile = () => import(/* webpackChunkName: "tables" */ '@/views/Pages/MyUserProfile.vue');

// Document
const Documents = () => import(/* webpackChunkName: "tables" */ '@/views/Documents/Documents.vue');
const BankSubmission = () => import(/* webpackChunkName: "tables" */ '@/views/Pages/Dashboard/Submission/SubmissionDashboard.vue');
const BankAnalysis = () => import(/* webpackChunkName: "tables" */ '@/views/Pages/Dashboard/BankAnalysis.vue');
const LeadAnalysis = () => import(/* webpackChunkName: "tables" */ '@/views/Pages/Dashboard/LeadAnalysis.vue');
const InitialProposal = () => import(/* webpackChunkName: "tables" */ '@/views/Pages/Dashboard/InitialProposal/InitialProposalReport.vue');
const ClientAccept = () => import(/* webpackChunkName: "tables" */ '@/views/Pages/Dashboard/InitialProposal/ClientAcceptanceReport/ClientAcceptanceReport.vue');
const ClientCashbackMasterList = () => import(/* webpackChunkName: "tables" */ '@/views/Pages/Dashboard/ClientCashbackMasterList/ClientCashbackMasterList.vue');
const CtosPurchase = () => import(/* webpackChunkName: "tables" */ '@/views/Pages/Dashboard/Operation/CtosPurchaseReport.vue');
const BizPerformance = () => import(/* webpackChunkName: "tables" */ '@/views/Pages/Dashboard/Operation/BizPerformance.vue');
const AgencySuccessRate = () => import(/* webpackChunkName: "tables" */ '@/views/Pages/Dashboard/Operation/AgencySuccessRateReport.vue');
const PropertySales = () => import(/* webpackChunkName: "tables" */ '@/views/Pages/Dashboard/Operation/PropertySalesReport.vue');
const CustomerAndCompanyProfile = () => import(/* webpackChunkName: "tables" */ '@/views/Pages/Dashboard/Finance/CustomerAndCompanyProfile.vue');
const CashflowForecast = () => import(/* webpackChunkName: "tables" */ '@/views/Pages/Dashboard/Finance/CashflowForecast.vue');
const CommissionReport = () => import(/* webpackChunkName: "tables" */ '@/views/Pages/Dashboard/Finance/CommissionReport.vue');

let componentsMenu = {
  path: '/components',
  component: DashboardLayout,
  redirect: '/components/buttons',
  name: 'Components',
  children: [
    {
      path: 'buttons',
      name: 'Buttons',
      component: Buttons
    },
    {
      path: 'cards',
      name: 'Cards',
      component: Cards
    },
    {
      path: 'grid-system',
      name: 'Grid System',
      component: GridSystem
    },
    {
      path: 'notifications',
      name: 'Notifications',
      component: Notifications
    },
    {
      path: 'icons',
      name: 'Icons',
      component: Icons
    },
    {
      path: 'typography',
      name: 'Typography',
      component: Typography
    }
  ]
};

let formsMenu = {
  path: '/forms',
  component: DashboardLayout,
  redirect: '/forms/elements',
  name: 'Forms',
  children: [
    {
      path: 'elements',
      name: 'Form elements',
      component:  FormElements
    },
    {
      path: 'components',
      name: 'Form components',
      component:  FormComponents
    },
    {
      path: 'validation',
      name: 'Form validation',
      component:  FormValidation
    }
  ]
};

let tablesMenu = {
  path: '/tables',
  component: DashboardLayout,
  redirect: '/table/regular',
  name: 'Tables menu',
  children: [
    {
      path: 'regular',
      name: 'Tables',
      component: RegularTables
    },
    {
      path: 'sortable',
      name: 'Sortable',
      component: SortableTables
    },
    {
      path: 'paginated',
      name: 'Paginated Tables',
      component: PaginatedTables
    }
  ]
};

let mapsMenu = {
  path: '/maps',
  component: DashboardLayout,
  name: 'Maps',
  redirect: '/maps/google',
  children: [
    {
      path: 'google',
      name: 'Google Maps',
      component: GoogleMaps
    },
    {
      path: 'vector',
      name: 'Vector Map',
      component: VectorMaps
    }
  ]
};

let pagesMenu = {
  path: '/pages',
  component: DashboardLayout,
  name: 'Pages',
  redirect: '/pages/user',
  children: [
    {
      path: 'user',
      name: 'User Page',
      component: User
    },
    {
      path: 'timeline',
      name: 'Timeline Page',
      component: TimeLine
    }
  ]
};

let authPages = {
  path: '/',
  component: AuthLayout,
  name: 'Authentication',
  children: [
    {
      path: '/home',
      name: 'Home',
      component: Home,
      meta: {
        noBodyBackground: true
      }
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/register',
      name: 'Register',
      component: Register
    },
    {
      path: '/pricing',
      name: 'Pricing',
      component: Pricing
    },
    {
      path: '/lock',
      name: 'Lock',
      component: Lock
    },
    { path: '*', component: NotFound }
  ]
};

let setupMenu = {
  path: '/setup',
  component: DashboardLayout,
  redirect: '/setup/users',
  name: 'Setup menu',
  children: [
    {
      path: 'users',
      name: 'Users',
      component: Users,
    },
    {
      path: 'addUsers',
      name: 'Add Users',
      component: AddUsers,
    },
    {
      path: 'editUsers/:id',
      name: 'Edit Users',
      component: EditUsers,
    },
    {
      path: 'my_profile',
      name: 'My Profile',
      component: MyUserProfile,
    },
  ]
};

let submissionMenu = {
  path: '/dashboard',
  component: DashboardLayout,
  redirect: '/dashboard/bank_submission',
  name: 'Dashboard',
  children: [
    {
      path: 'bank_submission',
      name: 'Bank Submission Report',
      component: BankSubmission
    },
    {
      path: 'bank_analysis',
      name: 'Bank Analysis',
      component: BankAnalysis
    },
    {
      path: 'initial_proposal',
      name: 'Initial Proposal Report',
      component: InitialProposal
    },
    {
      path: 'client_acceptance',
      name: 'Client Acceptance Report',
      component: ClientAccept
    },
    {
      path: 'lead_analysis',
      name: 'Lead Analysis',
      component: LeadAnalysis
    },
    {
      path: 'ctos_purchase',
      name: 'CTOS Purchase Report',
      component: CtosPurchase,
    },
    {
      path: 'client_cashback_master_list',
      name: 'Client Cashback Master List Report',
      component: ClientCashbackMasterList
    },
    {
      path: 'biz_performance',
      name: 'Biz Performance',
      component: BizPerformance,
    },
    {
      path: 'agency_success_rate',
      name: 'Agency Success Rate Report',
      component: AgencySuccessRate,
    },
    {
      path: 'property_sales',
      name: 'Property Sales Report',
      component: PropertySales,
    },
    {
      path: 'customer_company_profile',
      name: 'Customer And Company Profile',
      component: CustomerAndCompanyProfile,
    },
    {
      path: 'cashflow_forecast',
      name: 'Cashflow Forecast',
      component: CashflowForecast,
    },
    {
      path: 'commission_report',
      name: 'Commission Report',
      component: CommissionReport,
    },
  ]
};


const routes = [
  {
    path: '/',
    redirect: '/home',
    name: 'Home'
  },
  componentsMenu,
  formsMenu,
  tablesMenu,
  mapsMenu,
  pagesMenu,
  setupMenu,
  submissionMenu,
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/dashboard',
    name: 'Dashboard layout',
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: Dashboard
      },
      {
        path: 'alternative',
        name: 'Alternative',
        component: AlternativeDashboard,
        meta: {
          navbarType: 'light'
        }
      },
      {
        path: 'calendar',
        name: 'Calendar',
        component: Calendar
      },
      {
        path: 'charts',
        name: 'Charts',
        component: Charts
      },
      {
        path: 'widgets',
        name: 'Widgets',
        component: Widgets
      }
    ]
  },
  authPages,
];

export default routes;

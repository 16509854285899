var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('notifications'),_c('side-bar',[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Dashboard',
          icon: 'ni ni-single-copy-04 text-primary',
          
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Bank'}}},[_c('sidebar-item',{attrs:{"link":{ name: 'Bank Submission', path: '/dashboard/bank_submission' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Bank Analysis', path: '/dashboard/bank_analysis' }}})],1),_c('sidebar-item',{attrs:{"link":{ name: 'Lead Analysis', path: '/dashboard/lead_analysis' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Initial Proposal', path: '/dashboard/initial_proposal' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Client Acceptance', path: '/dashboard/client_acceptance' }}}),(_vm.user_role == 2 || _vm.user_role == 4)?_c('sidebar-item',{attrs:{"link":{ name: 'Operation'}}},[_c('sidebar-item',{attrs:{"link":{ name: 'CTOS Purchase', path: '/dashboard/ctos_purchase' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Client Cashback Master List', path: '/dashboard/client_cashback_master_list' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Biz Performance', path: '/dashboard/biz_performance' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Agency Success Rate Report', path: '/dashboard/agency_success_rate' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Property Sales Report', path: '/dashboard/property_sales' }}})],1):_vm._e(),_c('sidebar-item',{attrs:{"link":{ name: 'Finance'}}},[_c('sidebar-item',{attrs:{"link":{ name: 'Customer and Company Profile', path: '/dashboard/customer_company_profile' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Cashflow Forecast', path: '/dashboard/cashflow_forecast' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Commission Report', path: '/dashboard/commission_report' }}})],1)],1),(_vm.user_role == 1 || _vm.user_role == 2)?_c('sidebar-item',{attrs:{"link":{
          name: 'Setup',
          icon: 'ni ni-settings-gear-65 ',
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'User', path: '/setup/users' }}})],1):_vm._e()],1)],2),_c('div',{staticClass:"main-content"},[_c('dashboard-navbar',{attrs:{"type":_vm.$route.meta.navbarType}}),_c('div',{on:{"click":function($event){return _vm.$sidebar.displaySidebar(false)}}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }